import { useTableConfig } from '@components/tables/EditableTable';
import { AdditionRow } from '../../types';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';

export const useUpdateOrder = () => {
  const { rowsPropertyName } = useTableConfig();
  const { setValue } = useFormContext();

  const updateOrder = useCallback(
    (rows: AdditionRow[]) => {
      rows.forEach((_, index) => {
        setValue(`${rowsPropertyName}.${index}.orderOfAddition`, (index + 1).toString());
      });
    },
    [rowsPropertyName, setValue]
  );

  return { updateOrder };
};
