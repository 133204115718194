import { Select } from 'react-daisyui';
import { useFormContext, useWatch } from 'react-hook-form';
import { AcidBlendCellMeta, CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { Row } from '@components/tables/types';
import { useMemo } from 'react';
import { sortDropdownOptions } from '@utils/sortDropdownOptions';

export const AdditiveCell = <T extends Row>({ row, column }: CellProps<T>) => {
  const { rowsPropertyName } = useTableConfig();
  const meta = column.columnDef.meta as AcidBlendCellMeta;
  const { register } = useFormContext();

  const additiveTypeValue = useWatch({
    name: `${rowsPropertyName}.${row.index}.additiveType`,
    defaultValue: '',
  });
  const additiveTypeSelected = meta.parent.options.find(
    (parent) => parent.value.toLowerCase() === additiveTypeValue.toLowerCase()
  );

  const additivesSource = useMemo(
    () => additiveTypeSelected?.relatedKeys.map((option) => option.toLowerCase()) || [],
    [additiveTypeSelected?.relatedKeys]
  );

  const sortedOptions = sortDropdownOptions(meta.source.options);
  return (
    <Select
      size="sm"
      key={row.original.id}
      {...register(`${rowsPropertyName}.${row.index}.${column.id}`)}
      defaultValue={''}
    >
      <Select.Option
        key={'-1'}
        value=""
        disabled
      >
        Pick one
      </Select.Option>
      {sortedOptions
        .filter((option) => additivesSource.includes(option.value.toLowerCase()))
        .map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
          >
            {option.text}
          </Select.Option>
        ))}
    </Select>
  );
};
