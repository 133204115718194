import { getHCIAndSGByDegBe } from './acid-table-lookup';
import { CONVERSION_FACTORS } from './constants';

const getWaterDenisty_g_mL = (celsiusTemp: number): number =>
  0.99999 + 0.0000000175 * Math.pow(celsiusTemp, 3) - 0.00000612 * Math.pow(celsiusTemp, 2) + 0.0000254 * celsiusTemp;

const getWaterDenisty_lb_gal = (celsiusTemp: number): number =>
  (getWaterDenisty_g_mL(celsiusTemp) / CONVERSION_FACTORS.g_lb) * CONVERSION_FACTORS.ml_gal;

/**
 * function to get the density in g/ml and lb/gal for a given specific S.G
 * @param sg
 * @returns g/mL and lb/gal
 */
export const getDensity = (sg: number, celsiusTemp: number) => ({
  g_ml: sg * getWaterDenisty_g_mL(celsiusTemp),
  lb_gal: sg * getWaterDenisty_lb_gal(celsiusTemp),
});
/**
 * function to get the % HCI and S.G. by degrees Baumé
 * @param degBe
 * @returns lb HCl/gal and g HCl/mL
 */
export const getHCIPorcentage = (degBe: number, celsiusTemp: number) => {
  const { hci, sg } = getHCIAndSGByDegBe(degBe);
  const density = getDensity(sg, celsiusTemp);
  const hclPorc = hci / 100;
  return {
    lb_HCL_gal: hclPorc * density.lb_gal,
    g_HCL_mL: hclPorc * density.g_ml,
  };
};

/**
 * function to get the target S.G. of the acid needed to dilute to the target concentration
 * @param targetHCIAcidConc
 * @returns
 */
export const getTargetSG = (targetHCIAcidConc: number) => 0.005065 * targetHCIAcidConc + 0.999441;

/**
 * function to get the target density of the acid needed to dilute to the target concentration
 * @param targetHCIAcidConc
 * @returns g/mL
 */
export const getTargetDensity = (targetHCIAcidConc: number, celsiusTemp: number) =>
  getTargetSG(targetHCIAcidConc) * getWaterDenisty_g_mL(celsiusTemp);

/**
 * function to get the mass of the acid needed to dilute to the target concentration
 * @param targetVolume
 * @param targetHCIAcidConc
 * @returns g HCl
 */
export const getTargetAcidMass = (targetVolume: number, targetHCIAcidConc: number, celsiusTemp: number) =>
  targetVolume * getTargetDensity(targetHCIAcidConc, celsiusTemp) * (targetHCIAcidConc / 100);

/**
 * function to get the volume of the stock acid needed to dilute to the target concentration
 * @param targetVolume
 * @param degBe
 * @param targetHCIAcidConc
 * @returns mL
 */
export const getStockAcidVolume = (
  targetVolume: number,
  degBe: number,
  targetHCIAcidConc: number,
  celsiusTemp: number
) => getTargetAcidMass(targetVolume, targetHCIAcidConc, celsiusTemp) / getHCIPorcentage(degBe, celsiusTemp).g_HCL_mL;

/**
 * function to get the concentration of the stock acid needed to dilute to the target concentration
 * @param targetVolume
 * @param degBe
 * @param targetHCIAcidConc
 * @returns gal stock acid/1000 gal target vol (gpt)
 */
export const getStockAcidConc = (targetVolume: number, degBe: number, targetHCIAcidConc: number, celsiusTemp: number) =>
  (getStockAcidVolume(targetVolume, degBe, targetHCIAcidConc, celsiusTemp) / targetVolume) * 1000;

/**
 * function to get the volume of water needed to dilute the acid to the target concentration
 * @param targetVolume
 * @param targetHCIAcidConc
 * @returns mL
 */
export const getWaterVolume = (targetVolume: number, degBe: number, targetHCIAcidConc: number, celsiusTemp: number) =>
  targetVolume - getStockAcidVolume(targetVolume, degBe, targetHCIAcidConc, celsiusTemp);

/**
 * function to get the concentration of water needed to dilute the acid to the target concentration
 * @param targetVolume
 * @param targetHCIAcidConc
 * @returns gal water/1000 gal target vol (gpt)
 */
export const getWaterConc = (targetVolume: number, degBe: number, targetHCIAcidConc: number, celsiusTemp: number) =>
  (getWaterVolume(targetVolume, degBe, targetHCIAcidConc, celsiusTemp) / targetVolume) * 1000;

/**
 * function to get the volume/mass of other additive concentrations input by the user
 * @param targetVolume
 * @param targetAdditiveConc
 * @returns
 */
export const getOtherVolumeMass = (targetVolume: number, targetAdditiveConc: number) => {
  // Convert lb/1000gal to lb/gal
  const poundsPerGallon = targetAdditiveConc / 1000;
  // Apply conversion formula
  const result = poundsPerGallon * CONVERSION_FACTORS.g_lb * (1 / CONVERSION_FACTORS.ml_gal) * targetVolume;
  return result;
};

/**
 * function to get the volume of water
 * @param targetVolume
 * @param totalAdditivesVolume
 * @returns
 */
export const getWaterVolume2 = (targetVolume: number, totalAdditivesVolume: number) =>
  targetVolume - totalAdditivesVolume;

/**
 * function to get the concentration of water
 * @param targetVolume
 * @param totalAdditivesVolume
 * @returns
 */
export const getWaterConc2 = (targetVolume: number, waterVolume: number) => (waterVolume / targetVolume) * 1000;
