import { Row as RTRow, Table } from '@tanstack/react-table';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { useTableConfig } from '@components/tables/EditableTable';
import { useFormContext } from 'react-hook-form';
import { setWaterVolMass } from './utils';
import { AdditionRow } from '../../types';
import { CustomTableMeta, Row } from '@components/tables/types';
import { useUpdateOrder } from '../hooks/useUpdateOrder';

type ActionsCellProps<T extends Row> = {
  row: RTRow<T>;
  table: Table<T>;
};

export const ActionsCell = <T extends Row>({ row, table }: ActionsCellProps<T>) => {
  const meta = table.options.meta as CustomTableMeta<T>;
  const { rowsPropertyName } = useTableConfig();
  const { setValue, getValues } = useFormContext();
  const { updateOrder } = useUpdateOrder();

  const removeRow = () => {
    if (meta.removeRow) {
      meta?.removeRow(row.index);
      const rows = getValues(`${rowsPropertyName}`) as AdditionRow[];
      setWaterVolMass(rows, getValues(`sampleVolumeMl`), rowsPropertyName, setValue);
      updateOrder(rows);
    }
  };

  return (
    <div className="edit-cell-container">
      {
        <div className="edit-cell-action">
          <button
            type="button"
            className="rounded-full overflow-hidden p-0 focus:outline-none"
            key={row.original.id}
            onClick={removeRow}
            name="remove"
          >
            <MinusCircleIcon className="h-6 w-6 text-red-400 hover:bg-red-300 cursor-pointer hover:text-red-700 transform transition-transform duration-150 ease-in-out active:scale-90" />
          </button>
        </div>
      }
    </div>
  );
};
