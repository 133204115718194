/**
 * Define the minimum required properties for sorting
 */
type SortableOption = {
  value: string;
  text: string;
};

/**
 * Sorts an array of dropdown options alphabetically by text property using US English locale
 * @param options Array of any type that includes at least value and text properties
 * @returns Alphabetically sorted array
 */
export const sortDropdownOptions = <T extends SortableOption>(options: T[]): T[] => {
  return [...options].sort((a, b) => {
    // Convert to lowercase for case-insensitive comparison
    const textA = a.text.toLowerCase();
    const textB = b.text.toLowerCase();

    // Sort using US English locale
    return textA.localeCompare(textB, 'en-US', {
      sensitivity: 'base', // case-insensitive
      numeric: true, // proper sorting of numbers if they exist in text
    });
  });
};
