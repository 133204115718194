import { zDateRequired, zStringRequired } from '@components/zod/rules';
import * as z from 'zod';

const couponImageResultEnum = z.enum(['FrontPreTest', 'FrontPostTest', 'BackPreTest', 'BackPostTest']);
export const shapeEnum = z.enum([
  'CurvedWithHole',
  'CurvedNoHole',
  'FlatWithHole',
  'FlatNoHole',
  'CylinderWithHole',
  'CylinderNoHole',
  'Sphere',
  'FlatCircleWithHole',
  'FlatCircleNoHole',
  'CustomShape',
]);

const surfaceArea = z.object({
  surfaceArea: z.number(),
});

const hollowCylinder = surfaceArea.extend({
  diameter1: z.number(),
  diameter2: z.number(),
  length: z.number(),
});

const cylinder = surfaceArea.extend({
  diameter: z.number(),
  length: z.number(),
});

const sphere = surfaceArea.extend({
  diameter: z.number(),
});
const curvedSampleNoHole = surfaceArea.extend({
  width1: z.number(),
  width2: z.number(),
  length: z.number(),
  thickness: z.number(),
});
const curvedSampleWithHole = curvedSampleNoHole.extend({
  holeDiameter: z.number(),
});
const flatRectangularSquareNoHole = surfaceArea.extend({
  length: z.number(),
  width: z.number(),
  thickness: z.number(),
});
const flatRectangularSquareWithHole = flatRectangularSquareNoHole.extend({
  holeDiameter: z.number(),
});
const flatCircleNoHole = surfaceArea.extend({
  diameter: z.number(),
  thickness: z.number(),
});
const flatCircleWithHole = flatCircleNoHole.extend({
  holeDiameter: z.number(),
});
const customShape = surfaceArea.extend({});

const additionSchema = z.object({
  physicalState: z.string(),
  additiveType: z.string(),
  additive: z.string(),
  lotNumber: z.string(),
  concentration: z.string(),
  volMass: z.string(),
  solidVolMass: z.string(),
  orderOfAddition: z.string(),
  stockAcid: z.string(),
});

const resultsSchema = z.object({
  finalWeight: z.number(),
  weightLoss: z.number().default(0).optional(),
  corrosionLoss: z.number().default(0).optional(),
  corrosionRateMpy: z.number().default(0).optional(),
  corrosionRateMmpy: z.number().default(0).optional(),
  pittingIndex: zStringRequired,
  observations: zStringRequired,
  frontPreTestImg: z.unknown(),
  frontPostTestImg: z.unknown(),
  backPreTestImg: z.unknown(),
  backPostTestImg: z.unknown(),
});

export const schema = z.object({
  experimentId: z.string().optional(),
  date: zDateRequired,
  operator: zStringRequired,
  projectID: zStringRequired,
  equipment: zStringRequired,
  cellNumber: zStringRequired,
  temperatureDegF: z.number(),
  tempRampUpTime: z.number(),
  pressure: z.number(),
  incubationTime: z.number(),
  location: zStringRequired,
  customer: zStringRequired,
  purpose: zStringRequired,
  metallurgy: zStringRequired,
  metallurgyDensity: z.number().optional(),
  initialWeight: z.number(),
  shape: zStringRequired,
  sampleVolumeMl: z.number(),
  acidBlendTemperatureDegC: z.number().default(20),
  hollowCylinder: z.optional(hollowCylinder),
  cylinder: z.optional(cylinder),
  sphere: z.optional(sphere),
  curvedSampleNoHole: z.optional(curvedSampleNoHole),
  curvedSampleWithHole: z.optional(curvedSampleWithHole),
  flatRectangularSquareNoHole: z.optional(flatRectangularSquareNoHole),
  flatRectangularSquareWithHole: z.optional(flatRectangularSquareWithHole),
  flatCircleNoHole: z.optional(flatCircleNoHole),
  flatCircleWithHole: z.optional(flatCircleWithHole),
  customShape: z.optional(customShape),
  acidBlendAdditions: z.array(additionSchema).optional(),
  results: resultsSchema,
  surfaceArea: z.optional(z.number()),
});

export type FormInput = z.infer<typeof schema>;
export type AdditionSchema = z.infer<typeof additionSchema>;
export type CouponImageResultType = z.infer<typeof couponImageResultEnum>;
export type ShapeType = z.infer<typeof shapeEnum>;
