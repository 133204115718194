import { Authorization } from '@lib/Authorization';
import { Button } from 'react-daisyui';
import { PrintButton } from '../PrintButton';

type FooterProps = {
  reset: () => void;
  save: () => void;
};

const Footer = ({ reset, save }: FooterProps) => {
  return (
    <Authorization allowedRoles={['Admin', 'Scientist/Technician']}>
      <div className="flex justify-end gap-2 w-full">
        <Button
          id="save"
          color="neutral"
          size="xs"
          type="submit"
          //onClick={() => save()}
        >
          Save
        </Button>
        <Button
          color="secondary"
          size="xs"
          type="submit"
        >
          Save & Close
        </Button>
        <PrintButton
          components={[
            { id: 'print-coupon-section' },
            { id: 'print-acidblend-section' },
            { id: 'print-weight-section', width: 60, height: 40 },
          ]}
          fileName="worksheet"
        />
        <Button
          size="xs"
          color="primary"
          type="reset"
          onClick={() => reset()}
        >
          Cancel
        </Button>
      </div>
    </Authorization>
  );
};

export default Footer;
