import { orderOfMixing } from '@assets/images/acid-blend-recipe';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { forwardRef } from 'react';
import { Button, Modal } from 'react-daisyui';

interface OrderMixingModalProps {}

const OrderMixingModalComponent: React.ForwardRefRenderFunction<HTMLDialogElement, OrderMixingModalProps> = (
  props,
  ref
) => {
  return (
    <Modal
      ref={ref}
      className="w-full max-w-3xl"
    >
      <form method="dialog">
        <Button
          size="sm"
          color="ghost"
          shape="circle"
          className="absolute right-2 top-2"
        >
          <XMarkIcon className="w-5 h-5" />
        </Button>
      </form>
      <Modal.Header className="font-bold"></Modal.Header>
      <Modal.Body>
        <img
          src={orderOfMixing}
          alt="Acid Blend Mixing Order"
        />
      </Modal.Body>
    </Modal>
  );
};

export const OrderMixingModal = React.memo(forwardRef(OrderMixingModalComponent));
