import { forwardRef } from 'react';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { API_URL } from '@config';
import axiosClient from '@lib/api/client';
import { ProjectDetailsReportResponseDtoPaginatedListDto } from '@sdk-client/models';

export interface SelectAutocompleteOption {
  value: string;
  label: string;
}
interface Additional {
  page: number;
}

type SelectAutocompleteProps = {
  label?: string;
  error?: string;
  selectedOption: SelectAutocompleteOption | null;
  onChange: (newValue: SelectAutocompleteOption | null) => void;
  is_required?: string;
};

export const SelectAutocomplete = forwardRef<HTMLInputElement, SelectAutocompleteProps>(
  ({ label, error, selectedOption, onChange, is_required, ...rest }, ref) => {
    const loadOptions: LoadOptions<SelectAutocompleteOption, GroupBase<SelectAutocompleteOption>, Additional> = async (
      search: any,
      loadedOptions: any,
      { page }: any
    ) => {
      const response = await axiosClient({
        url: `/api/v1/reports/project-details`,
        baseURL: API_URL,
        method: 'get',
        params: {
          PageIndex: page,
          PageSize: 20,
          SearchTerm: search,
        },
      });
      const data = response.data as ProjectDetailsReportResponseDtoPaginatedListDto;

      const options =
        data.items?.map(
          (item) => ({ value: item.projectDefinition, label: item.projectName } as SelectAutocompleteOption)
        ) || [];
      return {
        options: options,
        hasMore: data.hasNextPage || false,
        additional: {
          page: page + 1,
        },
      };
    };

    const customStyles = {
      control: (base: any, state: any) => ({
        ...base,
        minHeight: '2rem',
        height: '2rem',
        padding: '0',
        backgroundColor: 'var(--fallback-b1, oklch(var(--b1)))',
        borderColor: error ? 'rgb(248 113 113)' : 'var(--fallback-bc, oklch(var(--bc) / 0.2))',
        borderWidth: '1px',
        borderRadius: 'var(--rounded-btn, 0.5rem)',
        boxShadow: 'none',
        backgroundImage:
          'linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%)',
        backgroundPosition: 'calc(100% - 20px) calc(1px + 50%), calc(100% - 16.1px) calc(1px + 50%)',
        backgroundSize: '4px 4px, 4px 4px',
        backgroundRepeat: 'no-repeat',
        '&:hover': {
          borderColor: 'var(--fallback-bc, oklch(var(--bc) / 0.2))',
        },
        cursor: 'pointer',
        outline: state.isFocused ? '2px solid var(--fallback-bc, oklch(var(--bc) / 0.2))' : 'none',
        outlineOffset: '0px',
      }),
      valueContainer: (base: any) => ({
        ...base,
        padding: '0 0.75rem',
        height: '2rem',
      }),
      input: (base: any) => ({
        ...base,
        margin: '0',
        padding: '0',
        height: '2rem',
        textTransform: 'none',
      }),
      indicatorsContainer: (base: any) => ({
        display: 'none',
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
      clearIndicator: (base: any) => ({
        ...base,
        padding: '0 0.5rem',
        height: '2rem',
      }),
      placeholder: (base: any) => ({
        ...base,
        fontSize: '0.875rem',
      }),
      option: (base: any, state: any) => ({
        ...base,
        backgroundColor: state.isSelected
          ? 'rgb(25, 103, 210)' // selected item color
          : state.isFocused
          ? 'rgba(179, 201, 238, 0.3)' // hover color
          : 'transparent',
        cursor: 'pointer',
      }),
      menu: (base: any) => ({
        ...base,
        backgroundColor: 'var(--fallback-b1, oklch(var(--b1)))',
        border: '1px solid var(--fallback-bc, oklch(var(--bc) / 0.2))',
        borderRadius: 'var(--rounded-btn, 0.5rem)',
      }),
    };

    return (
      <div className="flex w-full component-preview pb-3 items-center justify-center gap-2 font-sans">
        <div className="form-control w-full max-w-xs">
          <label className="label">
            <span className="label-text">
              {label}
              {is_required && <span className="label-text text-red-400"> * </span>}
            </span>
            {error && <span className="label-text-alt text-red-400">{error}</span>}
          </label>

          <AsyncPaginate
            {...rest}
            className="z-10 text-sm"
            value={selectedOption}
            loadOptions={loadOptions}
            onChange={onChange}
            additional={{
              page: 1,
            }}
            styles={customStyles}
            classNames={{
              control: () => 'select-sm select-bordered focus:outline-offset-0',
              menu: () => 'mt-1',
              option: () => 'text-sm py-2',
            }}
            debounceTimeout={300}
          />
        </div>
      </div>
    );
  }
);

SelectAutocomplete.displayName = 'SelectAutocomplete';
