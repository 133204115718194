import React from 'react';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { Button } from 'react-daisyui';

interface PrintComponent {
  id: string;
  width?: number;
  height?: number;
}

interface PrintButtonProps {
  components: PrintComponent[]; // componenents to capture
  fileName: string; // pdf name
}

export const PrintButton: React.FC<PrintButtonProps> = ({ components, fileName }) => {
  const handlePrint = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4'); // create a new a4 PDF document
    const margin = 10; // Margins for the PDF
    const pageWidth = pdf.internal.pageSize.getWidth() - 2 * margin; // Width of the page minus margins
    let currentY = margin; // position Y for the first image

    for (const component of components) {
      const element = document.getElementById(component.id);
      if (element) {
        // capture the element as a PNG image
        const dataUrl = await toPng(element, { quality: 1, pixelRatio: 2 });

        // Get the image dimensions
        const imgProps = await getImageDimensions(dataUrl);
        const imgWidth = component.width ?? pageWidth;
        const imgHeight = component.height ?? (imgProps.height * imgWidth) / imgProps.width;

        // verify if the image fits on the current page
        if (currentY + imgHeight > pdf.internal.pageSize.getHeight() - margin) {
          // if it doesn't fit, add a new page
          pdf.addPage();
          currentY = margin; // reset the Y position in the new page
        }

        // add the image to the PDF at the current position
        pdf.addImage(dataUrl, 'PNG', margin, currentY, imgWidth, imgHeight);

        // update the Y position for the next image
        currentY += imgHeight + margin; // add some space between images
      }
    }
    // save the PDF with the specified file name
    pdf.save(`${fileName}.pdf`);
  };

  // Function to get the dimensions of the image
  const getImageDimensions = (dataUrl: string): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  return (
    <Button
      color="secondary"
      size="xs"
      type="button"
      onClick={handlePrint}
    >
      Print
    </Button>
  );
};
