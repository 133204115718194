import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CellProps } from '@components/tables/EditableTable';
import { useTableConfig } from '@components/tables/EditableTable/ConfigProvider';
import { useCallback, useEffect, useRef } from 'react';
import { Row } from '@components/tables/types';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import { useUpdateOrder } from '../hooks/useUpdateOrder';
import { AdditionRow } from '../../types';

export const OrderOfAddition = <T extends Row>({ row, column }: CellProps<T>) => {
  const { getValues } = useFormContext();
  const { rowsPropertyName } = useTableConfig();
  const { fields, move } = useFieldArray({
    name: rowsPropertyName,
  });
  const { updateOrder } = useUpdateOrder();

  // Use a ref to track if we need to update the order
  const shouldUpdateOrder = useRef(true);

  const acidBlendAdditions = useWatch({
    name: `${rowsPropertyName}`,
    defaultValue: [],
  });

  const moveRow = useCallback(
    (index: number, direction: 'up' | 'down') => {
      const newIndex = direction === 'up' ? index - 1 : index + 1;
      if (newIndex >= 0 && newIndex < acidBlendAdditions.length) {
        move(index, newIndex);
        shouldUpdateOrder.current = true; // Set flag to true when rows are moved
      }
    },
    [acidBlendAdditions.length, move]
  );

  const index = row.index;
  const isFirstRow = index === 0;
  const isLastRow = index === acidBlendAdditions.length - 1;

  // Only update order when necessary
  useEffect(() => {
    if (fields.length > 0) {
      updateOrder(fields as AdditionRow[]);
    }
  }, [fields, updateOrder]);

  return (
    <div className="flex items-center space-x-2">
      <span>{getValues(`${rowsPropertyName}.${row.index}.${column.id}`)}</span>
      <div className="flex flex-col">
        <button
          type="button"
          onClick={!isFirstRow ? () => moveRow(index, 'up') : undefined}
          className={`h-4 w-4 ${isFirstRow ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
          disabled={isFirstRow}
          aria-disabled={isFirstRow}
        >
          <ChevronUpIcon />
        </button>

        <button
          type="button"
          onClick={!isLastRow ? () => moveRow(index, 'down') : undefined}
          className={`h-4 w-4 ${isLastRow ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
          disabled={isLastRow}
          aria-disabled={isLastRow}
        >
          <ChevronDownIcon />
        </button>
      </div>
    </div>
  );
};
