import { Collapse } from 'react-daisyui';
import { CollapseCustom } from './collapse-custom';
import { FileInput } from './FileUploader';
import { Dropdown, TextArea, TextBox, valueAsNumberOptional } from '@components/form';
import { Option } from '@components/form/Dropdown/types';
import { pittingIndexRef } from '@assets/images/corrosion-results';
import { useWatch } from 'react-hook-form';
import { schema } from '../schema';
import useFormContextExtended from './useFormContextExtended';
import { useEffect } from 'react';
import { getCorrosionLoss, getCorrosionRate_mmpy, getCorrosionRate_mpy, getWeightLoss } from './results-calculations';

type ResultsProps = {
  openDefault?: boolean;
  pittingIndexSource: Option[];
  experimentId?: string;
};

const Results = ({ openDefault = false, pittingIndexSource, experimentId }: ResultsProps) => {
  const { extendRegister, setValue, errors } = useFormContextExtended(schema);
  const initialWeightValue = useWatch({
    name: 'initialWeight',
    defaultValue: 0,
  });
  const incubationTimeValue = useWatch({
    name: 'incubationTime',
  });
  const metallurgyDensityValue = useWatch({
    name: 'metallurgyDensity',
  });
  const finalWeightValue = useWatch({
    name: 'results.finalWeight',
  });
  const surfaceAreaValue = useWatch({
    name: 'surfaceArea',
  });

  useEffect(() => {
    const toFixed = 3;
    const weightLoss = getWeightLoss(initialWeightValue, finalWeightValue);
    const roundedWeightLoss = Number(weightLoss.toFixed(toFixed));
    const corrosionLoss = getCorrosionLoss(initialWeightValue, finalWeightValue, surfaceAreaValue);
    const roundedCorrosionLoss = Number(corrosionLoss.toFixed(toFixed));
    const corrosionRateMpy = getCorrosionRate_mpy(
      initialWeightValue,
      finalWeightValue,
      metallurgyDensityValue,
      surfaceAreaValue,
      incubationTimeValue
    );
    const roundedCorrosionRateMpy = Number(corrosionRateMpy.toFixed(toFixed));
    const corrosionRateMmpy = getCorrosionRate_mmpy(
      initialWeightValue,
      finalWeightValue,
      metallurgyDensityValue,
      surfaceAreaValue,
      incubationTimeValue
    );
    const roundedCorrosionRateMmpy = Number(corrosionRateMmpy.toFixed(toFixed));
    setValue('results.weightLoss', roundedWeightLoss);
    setValue('results.corrosionLoss', roundedCorrosionLoss);
    setValue('results.corrosionRateMpy', roundedCorrosionRateMpy);
    setValue('results.corrosionRateMmpy', roundedCorrosionRateMmpy);
  }, [finalWeightValue, incubationTimeValue, initialWeightValue, metallurgyDensityValue, setValue, surfaceAreaValue]);

  return (
    <CollapseCustom openDefault={openDefault}>
      <Collapse.Title className="text-xl font-medium">Results</Collapse.Title>
      <Collapse.Content>
        <div className="flex flex-wrap gap-2">
          {/* Column 1: Inputs */}
          <div className="w-full md:w-1/5 px-2 mb-4">
            <div id="print-weight-section">
              <TextBox
                type="number"
                label="Initial Weight, g"
                value={initialWeightValue}
                readOnly={true}
              />

              <TextBox
                type="number"
                label="Final Weight, g"
                error={errors.results?.finalWeight?.message}
                {...extendRegister('results.finalWeight', {
                  setValueAs: valueAsNumberOptional,
                })}
              />
            </div>
            <TextBox
              type="number"
              readOnly={true}
              label="Weight Loss, g"
              error={errors.results?.weightLoss?.message}
              {...extendRegister('results.weightLoss', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              type="number"
              readOnly={true}
              label="Corrosion Loss (lb/ft2)"
              error={errors.results?.corrosionLoss?.message}
              {...extendRegister('results.corrosionLoss', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              type="number"
              readOnly={true}
              label="Corrosion Rate (mpy)"
              error={errors.results?.corrosionRateMpy?.message}
              {...extendRegister('results.corrosionRateMpy', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <TextBox
              type="number"
              readOnly={true}
              label="Corrosion Rate (mmpy)"
              error={errors.results?.corrosionRateMmpy?.message}
              {...extendRegister('results.corrosionRateMmpy', {
                setValueAs: valueAsNumberOptional,
              })}
            />
            <Dropdown
              label="Pitting Index"
              {...extendRegister('results.pittingIndex')}
              error={errors.results?.pittingIndex?.message}
              options={pittingIndexSource}
            />
          </div>
          {/* Column 2: FileInput */}
          <div className="w-full md:w-1/3 px-2 mb-4">
            <div className="flex flex-wrap -mx-2">
              <div className="w-full py-2 mb-2">
                <span>Front</span>
              </div>
              <div className="flex flex-row gap-2 px-2 mb-4">
                <FileInput
                  experimentId={experimentId}
                  couponImageResultType="FrontPreTest"
                  propertyPath="results.frontPreTestImg"
                  label="Upload Coupon Images Front"
                  footerLabel="Pre-Test"
                  error={errors.results?.frontPreTestImg?.message}
                  {...extendRegister('results.frontPreTestImg')}
                  onFileSelect={() => {}}
                />
                <FileInput
                  experimentId={experimentId}
                  couponImageResultType="FrontPostTest"
                  propertyPath="results.frontPostTestImg"
                  label="Upload Coupon Images Front"
                  footerLabel="Post-Test"
                  error={errors.results?.frontPostTestImg?.message}
                  {...extendRegister('results.frontPostTestImg')}
                  onFileSelect={() => {}}
                />
              </div>
              <div className="w-full py-2 mb-2">
                <span>Back</span>
              </div>
              <div className="flex flex-row gap-2 px-2 mb-4">
                <FileInput
                  experimentId={experimentId}
                  couponImageResultType="BackPreTest"
                  propertyPath="results.backPreTestImg"
                  label="Upload Coupon Images Back"
                  footerLabel="Pre-Test"
                  error={errors.results?.backPreTestImg?.message}
                  {...extendRegister('results.backPreTestImg')}
                  onFileSelect={() => {}}
                />
                <FileInput
                  experimentId={experimentId}
                  couponImageResultType="BackPostTest"
                  propertyPath="results.backPostTestImg"
                  label="Upload Coupon Images Back"
                  footerLabel="Post-Test"
                  error={errors.results?.backPostTestImg?.message}
                  {...extendRegister('results.backPostTestImg')}
                  onFileSelect={() => {}}
                />
              </div>
            </div>
          </div>
          {/* Column 3: Pitting Index Reference */}
          <div className="flex-auto md:w-1/3 px-2 mb-4 justify-end">
            <img
              src={pittingIndexRef}
              alt="Sample"
              className="object-contain max-h-[35rem]"
            />
          </div>
        </div>
        <div className="justify-self-start">
          <TextArea
            label="Observations"
            className="h-[20vh] w-[90vw] rounded-badge"
            error={errors.results?.observations?.message}
            {...extendRegister('results.observations')}
          />
        </div>
      </Collapse.Content>
    </CollapseCustom>
  );
};

export default Results;
